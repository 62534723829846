export function mediaSrcReplace() {
    const selector = 'data-background-item';
    const isMobileScreen = window.innerWidth < 768;
    const contentBlocks = document.querySelectorAll(`[${selector}]`);

    contentBlocks.forEach((block) => {
        const contentType = isMobileScreen ? 'mobile' : 'desktop';
        const content = block.parentElement.querySelector(`[${selector}="${contentType}"]`);

        if (content) {
            if (content.tagName.toLowerCase() === 'video') {
                const source = content.querySelector('source');
                if (source && source.dataset.src) {
                    const dataSrcUrl = new URL(source.dataset.src, window.location.href).href;
                    if (source.src !== dataSrcUrl) {
                        source.src = dataSrcUrl;
                        content.load();
                    }
                }
            } else if (content.tagName.toLowerCase() === 'img') {
                const dataSrc = content.dataset.src;
                const dataSrcSet = content.dataset.srcset;
                if (dataSrc && content.src !== dataSrc) {
                    content.src = dataSrc;
                }
                if (dataSrcSet && content.srcset !== dataSrcSet) {
                    content.srcset = dataSrcSet;
                }
            }
        }
    });
}
