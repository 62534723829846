const TargetMenu = () => {
    const spySections = document.querySelectorAll("[data-spy-section]");
    const spyNav = document.querySelector("[data-spy-nav]");
    const header = document.querySelector(".site-header");

    if (spySections.length && spyNav) {
        const activeClass = "active";
        let isoInstance;

        //Handler
        const isoHandler = (entry) => {
            const spyLink = document.querySelector(
                `[data-spy-link][href="#${entry.target.id}"]`
            );
            if (entry.isIntersecting) {
                spyLink.classList.add(activeClass);
            } else {
                spyLink.classList.remove(activeClass);
            }
        };

        //Options
        const isoOptions = () => {
            const navHeight = spyNav.offsetHeight;
            const headerHeight = header.offsetHeight;
            const navCompStyles = window.getComputedStyle(spyNav);
            const navTop = navCompStyles.getPropertyValue("top");
            let navTopValue = 0;
            if (navTop.includes("px")) {
                navTopValue = Number(navTop.replace("px", ""));
            }
            const topOffset = Math.ceil(navHeight + headerHeight + navTopValue + 2);
            const bottomOffset = Math.ceil(window.innerHeight - topOffset);
            return { rootMargin: `-${topOffset}px 0px -${bottomOffset}px 0px` };
        };

        //Main Function
        const iso = () => {
            if (typeof isoInstance === "object") {
                isoInstance.disconnect();
            }
            isoInstance = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    isoHandler(entry);
                });
            }, isoOptions());
            spySections.forEach((item) => isoInstance.observe(item));
        };

        //Init
        const resizeObserver = new ResizeObserver((entries) =>
            entries.forEach((entry) => {
                if (entry.borderBoxSize[0]) {
                    iso();
                }
            })
        );
        resizeObserver.observe(spyNav);
        resizeObserver.observe(
            document.querySelector("[data-spy-window-height]")
        );
    }

    // Horizontal scroll
    const scrollContainer = document.querySelectorAll('.sticky-menu');

    if (scrollContainer.length) {
        scrollContainer.forEach(item => {
            item.onwheel = function (e) {
                this.scrollLeft += e.deltaY * 0.5;
            };
            function checkScroll() {
                if (item.scrollWidth > item.clientWidth) {
                    item.classList.add('has-scroll');
                } else {
                    item.classList.remove('has-scroll');
                }
            }

            checkScroll();
            window.addEventListener('resize', checkScroll);
        })
    }
}

export default TargetMenu;