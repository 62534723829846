import Accordion from "./modules/accordion";
import Tabs from "./modules/tabs";
import OverlayMenu from "./modules/overlay-menu";
import Animate from "./modules/animate";
import TargetMenu from "./modules/target-menu";
import { dialog } from "./modules/dialog";
import Parallax from "./modules/lax";
import { mediaSrcReplace } from "./modules/src-replace";

//Accordion
Accordion();

//Tabs
Tabs();

//Overlay Menu
OverlayMenu();

//Animate
Animate();

//Target Menu
TargetMenu();

dialog()

Parallax()

mediaSrcReplace()

window.addEventListener('resize', mediaSrcReplace)