import lax from 'lax.js'

const Parallax = () => {

    window.onload = function () {

        lax.init()

        // Add a driver that we use to control our animations
        lax.addDriver('scrollY', function () {
            return window.scrollY
        })


        // Add animation bindings to elements
        lax.addElements('.parallax', {
            scrollY: {
                translateX: [
                    ["elInY", "elOutY"],
                    [200, -200],
                ]
            }
        })
    }
}

export default Parallax;